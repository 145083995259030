<template>
  <div class="container">
    <el-form label-width="100px" class="demo-ruleForm">
      <div class="infoBox" style="justify-content: space-between;">
        <div class="infoBox" style="height:30px;margin-bottom:20px">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-document-copy"
            @click="seeTemplate(), (dialogFormVisible = true), (setStatus = 1)"
            >药品模板</el-button
          >
          <el-button
            size="small"
            type="primary"
            icon="el-icon-document-checked"
            @click="(dialogFormVisible = true), (setStatus = 2)"
            >存模板</el-button
          >
        </div>
      </div>
      <div class="infoBox">
        <div class="infoBoxLeft">
          <h3 class="title">药品</h3>
          <el-table :data="tableData" style="width: 100%;margin-bottom:20px;">
            <el-table-column width="50" type="index" label="序号">
            </el-table-column>
            <el-table-column prop="medicine_name" label="药品名称">
            </el-table-column>
            <el-table-column prop="specification" label="规格型号">
            </el-table-column>
            <el-table-column prop="count" label="数量"> </el-table-column>
            <el-table-column prop="unit" label="单位"> </el-table-column>
            <!-- <el-table-column prop="count" label="库存"></el-table-column> -->
          </el-table>
          <template v-if="status === 0">
            <h3 class="title">编辑药品</h3>
            <el-table :data="orderData" style="width: 100%;margin-bottom:20px;">
              <el-table-column
                type="index"
                width="60"
                label="序号"
              ></el-table-column>
              <el-table-column prop="medicine_name" label="药品名称">
              </el-table-column>
              <el-table-column
                prop="specification"
                label="规格型号"
                width="100"
              >
              </el-table-column>
              <el-table-column prop="unit" label="单位" width="100">
              </el-table-column>
              <el-table-column prop="count" label="库存"> </el-table-column>
              <el-table-column prop="counts" label="数量" width="150">
                <template #default="scope">
                  <el-input-number
                    size="small"
                    v-model="scope.row.counts"
                    @change="handleChange"
                    :min="1"
                    :max="scope.row.count"
                    label="输入数量"
                  ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="150">
                <template #default="scope">
                  <el-button
                    @click.prevent="deleteRow2(scope.$index, orderData)"
                    type="text"
                    size="small"
                    >移除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-button style="margin-top:20px" @click="onSubmit" type="primary"
              >确认</el-button
            >
          </template>
        </div>
        <template v-if="status === 0">
          <div class="infoBoxRight">
            <h3 class="title">药品类</h3>
            <el-select
              v-model="categorys_type"
              style="margin-bottom: 15px"
              clearable
              placeholder="请选择药品分类"
              @change="onGetMedicine"
            >
              <el-option label="耗材" value="耗材"></el-option>
              <el-option label="药品" value="药品"></el-option>
              <el-option label="保健品" value="保健品"></el-option>
            </el-select>
            <el-input
              placeholder="请输入药品名称"
              style="width:200px; margin:0 10px; margin-bottom: 15px;"
              v-model="categorys_name2"
              clearable
            ></el-input>
            <el-button ref="onkey" type="primary" @click="onGetMedicine" @keyup.enter="onkey">搜索</el-button>
          <!--  <el-table
              :row-key="changecs2"
              ref="multipleTable2"
              :data="drugsList"
              tooltip-effect="dark"
              @row-click="rowClick2"
              :row-class-name="tableRowClassName"
              style="width: 100%"
              @selection-change="dtClassChange"
            > -->
			<el-table
			  :row-key="changecs2"
			  ref="multipleTable2"
			  :data="drugsList"
			  tooltip-effect="dark"
			  @row-click="rowClick2"
			  :row-class-name="tableRowClassName"
			  style="width: 100%"
			>
              <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
              <el-table-column
                prop="medicine_name"
                label="药品名称"
              ></el-table-column>
              <el-table-column
                prop="specification"
                label="规格型号"
              ></el-table-column>
              <el-table-column prop="count" label="库存"></el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                layout="total, prev, pager, next"
                :current-page="page"
                :page-size="limt"
                :total="drugsTotal"
                @current-change="ypPageChange"
              ></el-pagination>
            </div>
          </div>
        </template>
      </div>
    </el-form>
    <el-dialog
      :title="setStatus == 2 ? '新增模板' : '模板详情'"
      v-model="dialogFormVisible"
    >
      <el-form v-if="setStatus == 2" :model="form2" label-width="100px">
        <el-form-item label="创建人：">
          <span>{{ getUserName }}</span>
        </el-form-item>
        <el-form-item label="模板名称：">
          <el-input
            v-model="nameTemplate"
            width="200px"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="onAddMedical">确 定</el-button>
        </el-form-item>
      </el-form>
      <template v-else-if="setStatus == 6">
        <el-table :data="templateInfo" style="width: 100%; margin-bottom:20px;">
          <el-table-column
            type="index"
            width="50"
            label="序号"
          ></el-table-column>
          <el-table-column prop="name" label="治疗项目"></el-table-column>
          <el-table-column
            prop="specification"
            label="规格型号"
          ></el-table-column>
          <el-table-column prop="unit" label="单位"> </el-table-column>
          <el-table-column prop="counts" label="数量"></el-table-column>
          <el-table-column prop="msg" label="备注"> </el-table-column>
        </el-table>
      </template>

      <template v-else>
        <el-table :data="templateData" border style="width: 100%">
          <el-table-column
            type="index"
            width="50"
            label="序号"
          ></el-table-column>
          <el-table-column prop="id" label="模板编号"> </el-table-column>
          <el-table-column prop="name" label="模板名称"> </el-table-column>
          <!-- <el-table-column prop="disease_name" label="看诊项目"> </el-table-column> -->
          <el-table-column prop="create_time" label="创建日期">
          </el-table-column>
          <el-table-column label="操作" align="center" width="200">
            <template #default="scope">
              <el-button
                type="text"
                icon="el-icon-document-copy"
                @click="
                  onDiagnosisInfo(scope.row.id, 1), (dialogFormVisible = false)
                "
                >调用</el-button
              >
              <el-button
                type="text"
                icon="el-icon-tickets"
                @click="
                  onDiagnosisInfo(scope.row.id, 2),
                    (dialogFormVisible = true),
                    (setStatus = 6)
                "
                >详情</el-button
              >
              <el-popconfirm
                title="是否确定删除该模板？"
                @confirm="onDelMedical(scope.row.id)"
              >
                <template #reference>
                  <el-button type="text" icon="el-icon-delete">删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="page"
            :page-size="limt"
            :total="templateTotal"
            @current-change="seeTemplate2"
          ></el-pagination>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  getConfirmed,
  getMedicine,
  addConfirmed,
  addDrug,
  diagnosisDrug,
  diagnosisDrugs,
  delTemplate
} from "../../api/index";
export default {
  name: "drugsInfo",
  data() {
    return {
      // 耗材
      drugsList: [],
      page: 1,
      limt: 10,
      drugsTotal: 0,
      categorys_name2: "",
      categorys_type: "",
      orderData: [],
      id: "",
      nameTemplate: "", //模板名字
      templateData: [],
      templateTotal: 0,
      templateInfo: [],

      tableData: [],
      dialogFormVisible: false,
      setStatus: 1,
      status: 0, //缴费状态
      confirmeds: 0
    };
  },
  created() {
    this.onkey()
    let id = this.$route.query.id;
    let status = this.$route.query.status;
    this.confirmeds = Number(this.$route.query.confirmed);
    this.status = Number(status);
    this.id = id;
    this.init(); //回显
  },
  computed: {
    getUserName() {
      //获取专家名字
      return this.$store.state.userInfo.name;
    }
  },
  watch: {
    tableData(val) {
      val.forEach(item => {
        this.drugsList.forEach(items => {
          if (item.id === items.id) {
            this.$refs.multipleTable2.toggleRowSelection(item);
          }
        });
      });
    }
  },
  methods: {
    onkey(){
      document.onkeydown = e =>{
        if(e.keyCode===13){
          this.onGetMedicine()
        }
      }
    },
    async init() {
      let that = this;
      let type = this.categorys_type;
      let name = this.categorys_name2;
      let page = this.page;
      let limt = this.limt;
      let res = await getMedicine({ type, name, page, limt });
      this.drugsTotal = res.total;
      this.drugsList = res.list;
      let id = this.id;
      let res2 = await getConfirmed({ id });
      this.tableData = res2;
      that.drugsList.forEach(row => {
        if (this.tableData) {
          this.tableData.forEach(item => {
            if (row.id == item.medicine_id) {
              row.counts = item.count;
              that.rowClick2(row);
              console.log(row);
              // that.toggleSelection()
            }
          });
        }
      });
	  
	  //新增
	  this.dtClassChange(this.tableData);
    },
    changecs2(row) {
      return row.id;
    },
    deleteRow2(index, rows) {
      this.toggleSelection([rows[index]], false);
	  this.orderData.splice(index,1);
    },
    toggleSelection(rows, status = true) {
		console.log(rows);
      if (rows.filter(v=>v).length>0) {
        let _rows = [];
        rows.forEach(row => {
          const res = this.drugsList.find(v => v.id === row.id);
		  // const res = this.drugsList.find(v => v.id === row.medicine_id);
          res && _rows.push(res);
        });
		console.log(_rows);
        if (_rows.length > 0) {
          _rows.forEach(row => {
            this.$refs.multipleTable2.toggleRowSelection(row, status);
          });
        } else {
          rows.forEach(row => {
            this.$refs.multipleTable2.toggleRowSelection(row, status);
          });
        }
      } else {
        this.$refs.multipleTable2.clearSelection();
      }
    },
    getData(id) {
      getConfirmed({ id }).then(res => {
        this.tableData = res;
      });
    },

    onGetMedicine() {
      //获取药品列表
      let type = this.categorys_type;
      let name = this.categorys_name2;
      let page = this.page;
      let limt = this.limt;
	  
	  let that = this;
	  
      getMedicine({ type, name, page, limt }).then(res => {
		   let arr = [];
		  
        this.drugsTotal = res.total;
        if (this.drugsList.length > 0) {
          this.drugsList = res.list;
		  console.log(this.drugsList);
          this.drugsList.forEach(item => {
            item.showBar = true;
            res.list.forEach(items => {
              if (item.id == items.id) {
                item.showBar = false;
              }	  
            });
			
			
			if (this.tableData) {
				this.tableData.forEach(items => {
					if (item.id == items.medicine_id) {
						console.log(item.id == items.medicine_id)
						// item.counts = items.counts;
						arr.push(item);
					}
				});
			}
			
			
          });
        } else {
          this.drugsList = res.list;
        }
		
		console.log(arr);
		
		this.$nextTick(() => {
			that.toggleSelection(arr);
		})
      });
    },

    // 控制类型切换显示隐藏
    tableRowClassName: function({ row }) {
      if (row.showBar) {
        return "hidden-row";
      }
      return "";
    },

    rowClick2(row) {
      let rows = [row];
      if (rows) {
        rows.forEach(item => {
          this.$refs.multipleTable2.toggleRowSelection(item);
        });
      } else {
        this.$refs.multipleTable2.clearSelection();
      }
    },

    // 耗材翻页
    ypPageChange(val) {
      this.page = val;
      this.onGetMedicine();
    },
    dtClassChange(val) {
      const _ids = [];
      if (val.length >= 2) {
        for (let i = 0; i < val.length - 1; i++) {
          for (let j = i + 1; j < val.length; j++) {
            console.log("i-j", `${i}-${j}`);
            if (val[i].id === val[j].id) {
              _ids.push(val[i].id);
            }
          }
        }
      }
      this.orderData = val;
	  console.log('-------');
	  console.log(this.orderData);
        if (_ids.length > 0) {
          let index = this.orderData.findIndex(v => _ids.includes(v.id));
          while (index != -1) {
            this.deleteRow2(index, this.orderData);
            index = this.orderData.findIndex(v => _ids.includes(v.id));
          }
        }
    },

    // 确认
    onSubmit() {
      this.$confirm("是否选完所有耗材并提交?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let id = this.id;
        let arr = this.orderData;
        addConfirmed({ id, arr }).then(() => {
          this.$notify({
            title: "提示",
            message: "提交成功",
            type: "success"
          });
          this.$router.go(-1);
        });
      });
    },

    // 存模板
    onAddMedical() {
      if (!this.orderData.length > 0) {
        this.$message("请选择保存的耗材");
        return;
      }
      if (!this.nameTemplate) {
        this.$message("请输入模板名称");
        return;
      }
      this.orderData.map(item => {
        item.medicine_id = item.id;
      });
      let data = {
        name: this.nameTemplate,
        data: this.orderData
      };
      addDrug(data).then(() => {
        //  新增耗材模板
        this.dialogFormVisible = false;
        this.nameTemplate = "";
        this.$message.success("添加成功");
      });
    },

    // 查看治疗模板
    seeTemplate() {
      this.page = 1;
      this.seeTemplate2();
    },
    seeTemplate2() {
      let data2 = {
        page: this.page,
        limt: this.limt,
        type: 2
      };
      diagnosisDrug(data2).then(res => {
        this.templateData = res.list;
        this.templateTotal = res.total;
      });
    },
    // 删除
    onDelMedical(id) {
      delTemplate({ id }).then(() => {
        this.$message.success("删除成功");
        this.seeTemplate();
      });
    },
    // 详情
    onDiagnosisInfo(id, status) {
      // status 1：调用  2：详情
      diagnosisDrugs({ id }).then(res => {
        if (status == 2) {
          this.templateInfo = res;
        } else {
          res.map(item => {
            item.medicine_name = item.name;
          });
          this.orderData = res;
        }
      });
    }
  }
};
</script>
<style>
.el-table .hidden-row {
  display: none;
}
</style>
<style scoped>
.title,
.box {
  margin-bottom: 20px;
}
.box,
.infoBox {
  display: flex;
}
.infoBoxLeft,
.infoBoxRight {
  width: 45%;
}
.infoBoxLeft {
  margin-right: 5%;
}
.infoBoxRight {
  border-left: 1px solid #ccc;
  padding-left: 4%;
  box-sizing: border-box;
}
.linkWrap {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.linkBar {
  margin-top: 20px;
  text-align: left;
}
.textarea {
  display: block;
  margin-left: 0;
}
.line {
  margin-left: 10px;
}
.infoBoxBtn {
  justify-content: space-between;
}

/* 价格 */
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.containerLeft {
  display: flex;
  align-items: center;
}
.containerLeft_text {
  margin-right: 80px;
  font-size: 16px;
  color: #606266;
}
.containerLeft_text span {
  font-weight: bold;
  font-size: 18px;
}
.demo-ruleForm {
  width: 100%;
}
</style>
